/* 全局变量*/

html,
body {
  height: 100%;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}

img {
  display: block;
  max-width: 100%;
}

.pageWrap {
  width: 100%;
  height: 100%;
  background: url(./images/page4bg.jpg) no-repeat center;
  background-size: cover;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}

.page1,
.page2,
.page3,
.page4,
.page5,
.page6,
.page7 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.container {
  height: 100%;
  background-size: 0.133333rem 0.266667rem;
}

.bgSite {
  width: 100%;
  height: 50%;
  position: fixed;
  z-index: -1;
}

.nopage1 {
  height: 100%;
  width: 100%;
  /* background: blue; */
}

.swiper-container-son {
  /* border: 1px solid red; */
}



.page2 {
  height: 100%;
  position: relative;
  background: url(./images/page2bg2.jpg) no-repeat center;
  background-size: 101% 101%;
}

.page2Inner {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.page2mobile {
  width: 100%;
}

.page2btn {
  position: absolute;
  height: 1.066667rem;
  line-height: 1.066667rem;
  width: 8rem;
  bottom: 0.533333rem;
  background: #ffffff;
  -webkit-box-shadow: 0 0.053333rem 0.346667rem 0 rgba(141, 141, 141, .5);
  box-shadow: 0 0.053333rem 0.346667rem 0 rgba(141, 141, 141, .5);
  border-radius: 0.533333rem;
  text-align: center;
}

.page2btn .loadIcon {
  font-family: PingFangSC-Medium;
  font-size: 0.426667rem;
  color: #f54343;
  letter-spacing: 0.22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

:global(.hairlines) .page2btn .loadIcon {
  letter-spacing: 0.5px;
}

.page2btn .loadIcon img {
  width: 0.426667rem;
  margin-left: 0.16rem;
}

.otherbtn {
  z-index: 2;
  position: absolute;
  height: 1.066667rem;
  line-height: 1.066667rem;
  width: 8rem;
  background: #f54343;
  -webkit-box-shadow: 0 0.053333rem 0.346667rem 0 rgba(141, 141, 141, .5);
  box-shadow: 0 0.053333rem 0.346667rem 0 rgba(141, 141, 141, .5);
  border-radius: 0.533333rem;
  text-align: center;
  color: #fff;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.otherbtn .loadIcon {
  font-family: PingFangSC-Medium;
  font-size: 0.426667rem;
  letter-spacing: 0.22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

:global(.hairlines) .otherbtn .loadIcon {
  letter-spacing: 0.5px;
}

.otherbtn .loadIcon img {
  width: 0.426667rem;
  margin-left: 0.16rem;
}

.logoWrap {
  position: absolute;
  top: 1.04rem;
  left: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  /* border: 1px solid #fff; */
  color: #fff;
}

.logoWrap .logo2x {
  width: 6.853333rem;
  margin-bottom: 0.293333rem;
}

.logoWrap .line {
  width: 6.8rem;
  margin-bottom: 0.133333rem;
}

.logoWrap .logoContent {
  /* border: 1px solid #fff;*/
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: PingFangSC-Regular;
  font-size: 0.266667rem;
  letter-spacing: 0.061067rem;
  line-height: 0.373333rem;
  height: 0.373333rem;
}

.logoWrap .logoContent .circle {
  width: 0.08rem;
  height: 0.08rem;
  background-color: #fff;
  border-radius: 50%;
}

.page3 {
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* background: url(./gif/p2Gif3.gif) no-repeat center; */
  background-size: 101% 100%;
}

.page3 .lottie {
  /* border: 1px solid red;*/
  /* position: absolute;
        z-index: -1;*/
  /* display: block;
        object-fit: fill;
        width: 100%;
        absolute:*/
}

.page3 .page3Content {
  /* border: 1px solid red;*/
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 1.946667rem;
  left: auto;
  width: 8.266667rem;
  z-index: 99;
}

.page3 .page3Content .leftText {
  /* border: 1px solid red;*/
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.page3 .page3Content .leftText .left {
  /* border: 1px solid red;*/
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: PingFangSC-Regular;
  font-size: 0.373333rem;
  color: #7e8aa7;
  line-height: 0.88rem;
  text-shadow: 0 0.026667rem 0 rgba(255, 255, 255, .7);
  margin-top: 0.186667rem;
}

.page3 .page3Content .leftText .left .bigText {
  font-family: PingFangSC-Semibold;
  font-weight: bold;
  font-size: 0.533333rem;
  color: #66718b;
  line-height: 0.88rem;
  text-shadow: 0 0.026667rem 0 rgba(255, 255, 255, .7);
  margin-right: 0.133333rem;
}

.page3 .page3Content .rightText {
  /* border: 1px solid red;*/
}

.page3 .page3Content .rightText .right {
  /* border: 1px solid red;*/
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: PingFangSC-Regular;
  font-size: 0.373333rem;
  color: #7e8aa7;
  line-height: 0.88rem;
  text-shadow: 0 0.026667rem 0 rgba(255, 255, 255, .7);
  margin-top: 0.186667rem;
}

.page3 .page3Content .rightText .right .bigText {
  font-family: PingFangSC-Semibold;
  font-weight: bold;
  font-size: 0.533333rem;
  color: #66718b;
  line-height: 0.88rem;
  text-shadow: 0 0.026667rem 0 rgba(255, 255, 255, .7);
  margin-right: 0.133333rem;
}

.go {
  position: absolute;
  font-size: 0;
  font-family: PingFang SC;
  font-weight: 300;
  color: #ffffff;
  width: 100%;
  text-align: center;
  bottom: 2.133333rem;
  left: 0;
}

.go a {
  font-family: PingFangSC-Regular;
  font-size: 0.4rem;
  color: #ffffff;
  letter-spacing: 0.2px;
}

:global(.hairlines) .go a {
  letter-spacing: 0.5px;
}

.go span {
  display: inline-block;
  width: 0.293333rem;
  height: 0.293333rem;
  background: url(./images/go.png) no-repeat center;
  background-size: contain;
  margin-left: 0.08rem;
}

.page3 .img,
.page4 .img,
.page5 .img,
.page6 .img,
.page7 .img {
  width: 100%;
  position: absolute;
  bottom: 2.666667rem;
  left: auto;
}

.tit {
  position: absolute;
  width: 6.213333rem;
  top: 1.04rem;
  left: 50%;
  z-index: 2;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.page4 .contentWrap,
.page5 .contentWrap,
.page6 .contentWrap,
.page7 .contentWrap {
  position: absolute;
  top: 2.533333rem;
  left: auto;
  width: 8.266667rem;
  font-family: PingFangSC-Regular;
  font-size: 0.373333rem;
  color: #4d4d4d;
}

.page4 .contentWrap .contentTitleWrap,
.page5 .contentWrap .contentTitleWrap,
.page6 .contentWrap .contentTitleWrap,
.page7 .contentWrap .contentTitleWrap {
  /* display: flex;*/
  /* align-items: center;*/
}

.page4 .contentWrap .contentTitleWrap .contentTitle,
.page5 .contentWrap .contentTitleWrap .contentTitle,
.page6 .contentWrap .contentTitleWrap .contentTitle,
.page7 .contentWrap .contentTitleWrap .contentTitle {
  font-family: PingFangSC-Semibold;
  font-weight: bold;
  font-size: 0.48rem;
  color: #212223;
}

.swiper-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #eeeeee;
}

.slide2 .swiper-slide {
  background: #2ac3cb;
}

.pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  bottom: 2.266667rem;
  left: 50%;
  z-index: 10;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}

.bullet {
  width: 0.106667rem;
  height: 0.106667rem;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all 0.05s ease-in;
  transition: all 0.05s ease-in;
  background: #9e9e9e;
  margin-right: 0.106667rem;
}

.bullet.active {
  background: red;
}

:global(.swiper-pagination) {
  bottom: 1.866667rem !important;
}

:global(.swiper-pagination-bullet) {
  width: 0.106667rem;
  height: 0.106667rem;
}

:global(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
  background: #9e9e9e;
  width: 0.32rem;
  height: 0.106667rem;
  border-radius: 0.066667rem;
}

.lottie {
  position: absolute;
  z-index: -1;
}

.indexLottie {
  position: absolute;
  border-radius: 0.373333rem;
}

.video_player {
  position: absolute;
  display: block;
  -o-object-fit: fill;
  object-fit: fill;
  border: none;
  width: 5.333333rem;
  /* height: 804px; */
  border-radius: 0.4rem;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0.346667rem 0 rgba(104, 108, 147, .5);
  box-shadow: 0 0 0.346667rem 0 rgba(104, 108, 147, .5);
}

.video_player2 {
  width: 100%;
}

.site {
  width: 5.333333rem;
  height: 10.72rem;
  border-radius: 0.4rem;
  background: #fff;
}

.gif_player {
  position: absolute;
  display: block;
  -o-object-fit: fill;
  object-fit: fill;
  border: none;
  width: 5.333333rem;
  border-radius: 0.373333rem;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.page3Img {
  pointer-events: none;
  width: 100%;
}

.p2IMG {
  position: absolute;
  top: 25%;
  left: auto;
}

/* 高度适配*/

@media screen and (max-height: 580px) {
  .video_player {
    /* outline: 5px solid green; */
    width: 5.333333rem;
  }

  .site {
    width: 5.333333rem;
  }
}

@media screen and (min-height: 580px) and (max-height: 600px) {
  .tit.QQ {
    /* border: 5px solid black; */
    top: 0.506667rem;
  }

  .video_player {
    /* outline: 5px solid blue; */
    width: 4rem;
  }

  .site {
    width: 4rem;
  }
}

@media screen and (min-height: 600px) and (max-height: 630px) {
  .tit.Safari {
    /* border: 5px solid black; */
    top: 0.773333rem;
  }

  .tit.Uc {
    /* border: 5px solid black; */
    top: 0.506667rem;
  }

  .video_player {
    /* outline: 5px solid Pink; */
    width: 5.333333rem;
  }

  .video_player.Safari {
    /* border: 5px solid black; */
    width: 4.266667rem;
  }

  .video_player.Uc {
    /* border: 5px solid black; */
    width: 4.266667rem;
  }

  .video_player.QQ {
    /* border: 5px solid black; */
    width: 5.066667rem;
  }

  .video_player.is360 {
    /* border: 5px solid green; */
    width: 4.266667rem;
  }

  .site {
    width: 5.2rem;
  }
}

@media screen and (min-height: 630px) and (max-height: 640px) {
  .video_player {
    /* outline: 5px solid black; */
    width: 5.333333rem;
  }

  .video_player.WeChat {
    width: 4.666667rem;
  }

  .site {
    width: 5.333333rem;
  }
}

@media screen and (min-height: 640px) and (max-height: 650px) {
  .video_player {
    /* outline: 5px solid #a200d3; */
    width: 5.6rem;
  }

  .site {
    width: 5.6rem;
  }
}

@media screen and (min-height: 650px) and (max-height: 670px) {
  .video_player {
    /* outline: 5px solid red; */
    width: 5.066667rem;
  }

  .video_player.Uc {
    /* outline: 5px solid red; */
    width: 5.6rem;
  }

  .video_player.Baidu {
    /* outline: 5px solid red; */
    width: 5.6rem;
  }

  .video_player.Baidu.Ios {
    /* outline: 5px solid red; */
    width: 4.8rem;
  }

  .video_player.OPPO {
    /* outline: 5px solid red; */
    width: 5.6rem;
  }

  .site {
    width: 5.066667rem;
  }
}

@media screen and (min-height: 670px) and (max-height: 700px) {
  .video_player {
    /* outline: 5px solid #34a2f9; */
    width: 5.866667rem;
  }

  .video_player.Quark {
    /* outline: 5px solid #34a2f9; */
    width: 5.333333rem;
  }
}

@media screen and (min-height: 700px) and (max-height: 750px) {
  .video_player {
    /* outline: 5px solid #25a481; */
    width: 6rem;
  }

  .video_player.Uc {
    /* outline: 5px solid #25a481; */
    width: 5.6rem;
  }

  .video_player.QQ {
    /* outline: 5px solid #25a481; */
    width: 5.333333rem;
  }

  .video_player.Safari {
    /* outline: 5px solid #25a481; */
    width: 5.6rem;
  }
}

@media screen and (min-height: 750px) and (max-height: 800px) {
  .video_player {
    /* outline: 5px solid yellow; */
    width: 6rem;
  }

  .site {
    width: 6rem;
  }
}
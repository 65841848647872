.contentWrap {
  position: absolute;
  top: 2.533333rem;
  left: auto;
  width: 8.266667rem;
  font-family: PingFangSC-Regular;
  font-size: 0.373333rem;
  color: #4d4d4d;
  z-index: 99;
}

.contentWrap .contentTitleWrap {
  /* display: flex; */
  /* align-items: center; */
}

.contentWrap .contentTitleWrap .contentTitle {
  font-family: PingFangSC-Semibold;
  font-weight: bold;
  font-size: 0.48rem;
  color: #212223;
}

/* @media screen and (min-width: 750px) {
    .contentWrap {
        font-size: 14px;
        & .contentTitleWrap {
            & .contentTitle {
            }
        }
    }
} */

/* 高度适配*/

@media screen and (max-height: 580px) {
  .contentWrap {
    /* outline: 5px solid green; */
  }
}

@media screen and (min-height: 580px) and (max-height: 600px) {
  .contentWrap {
    /* outline: 5px solid blue; */
  }

  .contentWrap.QQ {
    /* border: 5px solid black; */
    top: 1.6rem;
  }
}

@media screen and (min-height: 600px) and (max-height: 630px) {
  .contentWrap {
    /* outline: 5px solid Pink; */
  }

  .contentWrap.Safari {
    /* border: 5px solid black; */
    top: 2rem;
  }

  .contentWrap.QQ {
    /* border: 5px solid black; */
    top: 1.733333rem;
  }

  .contentWrap.Vivo {
    /* border: 5px solid black; */
    top: 2.266667rem;
  }

  .contentWrap.Uc {
    /* border: 5px solid black; */
    top: 1.733333rem;
  }

  .contentWrap.is360 {
    /* border: 5px solid black; */
    top: 2.266667rem;
  }
}

@media screen and (min-height: 630px) and (max-height: 640px) {
  .contentWrap {
    /* outline: 5px solid black; */
    top: 2.266667rem;
  }
}

@media screen and (min-height: 640px) and (max-height: 650px) {
  .contentWrap {
    /* outline: 5px solid #a200d3; */
  }
}

@media screen and (min-height: 650px) and (max-height: 670px) {
  .contentWrap {
    /* outline: 5px solid red; */
  }

  .contentWrap.Baidu {
    /* border: 5px solid black; */
    top: 2.266667rem;
  }
}

@media screen and (min-height: 670px) and (max-height: 700px) {
  .contentWrap {
    /* outline: 5px solid #34a2f9; */
  }
}

@media screen and (min-height: 700px) and (max-height: 750px) {
  .contentWrap {
    /* outline: 5px solid #25a481; */
  }
}

@media screen and (min-height: 750px) and (max-height: 800px) {
  .contentWrap {
    /* outline: 5px solid yellow; */
  }
}
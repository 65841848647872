.img {
  width: 100%;
  position: absolute;
  bottom: 3.333333rem;
  right: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* 高度适配*/

@media screen and (max-height: 580px) {
  .img {
    /* outline: 5px solid green; */
  }
}

@media screen and (min-height: 580px) and (max-height: 600px) {
  .img {
    /* outline: 5px solid blue; */
    width: 75%;
  }

  .img.QQ {
    /* border: 5px solid black; */
    bottom: 2.133333rem;
    /* width: calc(75%); */
  }
}

@media screen and (min-height: 600px) and (max-height: 630px) {
  .img {
    /* outline: 5px solid Pink; */
    bottom: 2.133333rem;
    width: 80%;
  }

  .img.Safari {
    /* border: 5px solid black; */
    /* width: calc(80%); */
  }

  .img.QQ {
    /* border: 5px solid black; */
    /* width: calc(95%); */
  }

  .img.Uc {
    /* border: 5px solid black; */
    /* width: calc(80%); */
  }

  .img.is360 {
    /* border: 5px solid black; */
    /* width: calc(80%); */
  }
}

@media screen and (min-height: 630px) and (max-height: 640px) {
  .img {
    bottom: 2.133333rem;
    width: 85%;
  }

  .img.WeChat {
    /* bottom: 160px; */
  }
}

@media screen and (min-height: 640px) and (max-height: 650px) {
  .img {
    /* outline: 5px solid #a200d3; */
    bottom: 2.666667rem;
  }
}

@media screen and (min-height: 650px) and (max-height: 670px) {
  .img {
    /* outline: 5px solid red; */
    bottom: 2.533333rem;
    width: 85%;
  }

  .img.OPPO {
    /* outline: 5px solid red; */
    bottom: 2.533333rem;
    /* width: calc(100%); */
  }

  .img.Uc {
    /* outline: 5px solid red; */
    bottom: 2.533333rem;
    /* width: calc(100%); */
  }

  .img.Baidu {
    /* outline: 5px solid red; */
    bottom: 2.533333rem;
    /* width: calc(85%); */
  }
}

@media screen and (min-height: 670px) and (max-height: 700px) {
  .img {
    /* outline: 5px solid #34a2f9; */
    bottom: 2.666667rem;
  }
}

@media screen and (min-height: 700px) and (max-height: 750px) {
  .img {
    /* outline: 5px solid #25a481; */
    width: 90%;
    bottom: 2.666667rem;
  }

  .img.Quark {
    /* border: 5px solid black; */
    bottom: 2.666667rem;
  }

  .img.Safari {
    /* border: 5px solid black; */
    bottom: 2.666667rem;
  }
}

@media screen and (min-height: 750px) and (max-height: 800px) {
  .img {
    /* outline: 5px solid yellow; */
  }
}